import React, { useState, useMemo } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import ArticleProductCard from '../components/shared/uiComponents/articleProductCard'
import Button from '../components/shared/uiComponents/button'
import BoxedContainer from '../components/shared/boxedContainer'
import {
  mobileVW,
  colors,
  desktopBreakpoint,
  font,
  headerHeight,
} from '../styles'
import { useLayoutCtx, useDictionaryCtx } from '../hooks/context'

const Wrapper = styled.div`
  margin-bottom: ${mobileVW(80)};
  margin-top: ${mobileVW(100)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-bottom: 60px;
    margin-top: 48px;
    padding: ${headerHeight.desktop}px 56px 0;
  }
`

const Title = styled.div`
  font-family: ${font.ben};
  font-size: ${mobileVW(30)};
  margin-bottom: ${mobileVW(30)};
  text-align: center;
  text-transform: lowercase;
  font-weight: normal;
  @media (min-width: ${desktopBreakpoint}) {
    font-size: 36px;
    margin-bottom: 35px;
  }
`

const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 ${mobileVW(16)};
  margin-bottom: ${mobileVW(48)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0;
    max-width: 1170px;
    margin: auto;
    margin-bottom: 18px;

    h3 {
      font-size: 28px;
    }

    .product_card:not(:nth-child(4n + 1)) {
      margin-right: 40px;
    }
  }

  @media (min-width: 1280px) {
    justify-content: flex-start;
  }

  .product_card:first-of-type {
    border: solid ${mobileVW(2)} ${colors.beigeUltraLight};
    padding: ${mobileVW(18)} ${mobileVW(18)} ${mobileVW(23)} ${mobileVW(19)};
    border-radius: ${mobileVW(10)};
    width: 100%;
    margin-bottom: ${mobileVW(35)};
    min-height: ${mobileVW(514)};

    .button_wrapper {
      display: none;
    }

    h3 {
      -webkit-line-clamp: 2;
    }

    p {
      font-size: ${mobileVW(16)};
      line-height: ${mobileVW(22)};
      -webkit-line-clamp: 2;
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 16px;
        line-height: 22px;
        -webkit-line-clamp: 4;
      }
    }

    @media (min-width: ${desktopBreakpoint}) {
      flex-direction: row;
      border: solid 1px ${colors.beigeUltraLight};
      padding: 18px;
      border-radius: 10px;
      min-height: 376px;
      max-height: 376px;
      img {
        max-height: 340px;
      }
      margin-bottom: 30px;
    }

    .card-text-wrapper {
      > div {
        text-align: center;
      }
      @media (min-width: ${desktopBreakpoint}) {
        display: flex;
        flex-basis: 50%;
        padding: 0 70px;
        align-items: center;
        justify-content: center;
      }
    }

    .card-image-wrapper {
      width: 100%;
      height: ${mobileVW(313)};
      @media (min-width: ${desktopBreakpoint}) {
        height: 340px;
        flex-basis: 50%;
      }
    }
    h3 {
      color: ${colors.brownLight};
      font-size: ${mobileVW(24)};
      line-height: ${mobileVW(27)};
      text-transform: lowercase;
      @media (min-width: ${desktopBreakpoint}) {
        font-size: 36px;
        line-height: 39px;
      }
    }

    p {
      color: ${colors.brownDarkest};
    }
  }
`

const AllArticles = () => {
  const dictionary = useDictionaryCtx()
  const { articles } = useLayoutCtx()
  const [limit, setLimit] = useState(12)

  return (
    <>
      <Wrapper>
        <BoxedContainer>
          <Title>{dictionary.allArticles}</Title>
          <Gallery>
            {articles.map((article, i) =>
              i <= limit ? (
                <ArticleProductCard key={i} content={article} />
              ) : null,
            )}
          </Gallery>
          {limit < articles.length ? (
            <Button
              cta={() => setLimit(limit + 60)}
              ctaType={'terciary'}
              text={'load more articles'}
            />
          ) : null}
        </BoxedContainer>
      </Wrapper>
    </>
  )
}

export default AllArticles
